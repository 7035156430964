let navLinks = document.querySelectorAll('.page-nav-item');
let navSections = document.querySelectorAll('.page-nav-section');

window.onscroll = function() {
  let current = "media-1";

  navSections.forEach(section => {
    const sectionTop = section.offsetTop;
    if (scrollY >= sectionTop - 350) {
      current = section.querySelector('.anchor').id;
    }
  });

  navLinks.forEach(link => {
    link.classList.remove('active');
    if (link.href.includes(current)) {
      link.classList.add("active");
    } else {
      link.classList.remove("active");
    }
  });
}